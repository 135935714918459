
import { Component, Vue } from 'vue-property-decorator'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import { drawPolygon } from '@/utils/formatData'
import { TableData } from '@/types/manhole'

require('echarts-liquidfill')

@Component({
  name: 'DetailMap'
})
export default class DetailMap extends Vue {
  private drawer = false
  private leftDrawer = false
  private map: AMap.Map | undefined = undefined
  private ploygons: Array<AMap.Polygon> = []
  private markers: Array<AMap.Marker> = []

  private onlineImg = require('@/assets/icon/manhole/normal.svg')
  private onlineImgCursor = require('@/assets/icon/manhole/normal-big.svg')
  private offImg = require('@/assets/icon/manhole/offline.svg')
  private offImgCursor = require('@/assets/icon/manhole/offline-big.svg')
  private warningImg = require('@/assets/icon/manhole/warning.svg')
  private warningImgCursor = require('@/assets/icon/manhole/warning-big.svg')

  private drawerData: TableData | null = null

  private searchInfo = {
    manholeName: '',
    areaId: '',
    manholeType: '',
    deviceStatus: ''
  }

  private tableData: TableData[] =[]
  private areaList = []
  private typeList = []
  private page = 1
  private total = 0
  private size = 10
  private loading=false
  get projectId () {
    return this.$route.params.projectId as string
  }

  $refs!: {
    statusEcharts: any;
    temperature: any;
    humidity: any;
  }

  created () {
    this.getProjectLocation()
    this.getTypeList()
    this.getAreaList()
  }

  destroyed (): void {
    if (this.markers) {
      this.markers.forEach((item: AMap.Marker) => {
        item.off('click', this.drawerOpen)
      })
    }
    if (this.ploygons) {
      this.ploygons.forEach(item => {
        item.off('click', this.drawerClose)
      })
    }
    if (this.map) {
      this.map.off('click', this.drawerClose)
      this.map.destroy()
    }
  }

  getData (allShow?: boolean) {
    this.loading = true
    const info = allShow ? { ...this.searchInfo } : {
      page: this.page,
      size: this.size,
      ...this.searchInfo
    }
    this.$axios.get(this.$apis.manhole.selectManholeInfoList, {
      ...info,
      projectId: this.projectId
    }).then(res => {
      if (res) {
        this.total = res.total || 0
        this.tableData = res.list || []
        this.drawer && this.drawerClose()
        this.drawMarker(res.list)
      }
    }).finally(() => {
      this.loading = false
    })
  }

  getAreaList () {
    this.$axios.get(this.$apis.project.selectProjectAreaByList, {
      projectId: this.projectId,
      notAllArea: '1'
    }).then(res => {
      this.areaList = res.list || []
    })
  }

  getTypeList () {
    this.$axios.get(this.$apis.common.selectDicByList, {
      dicType: 'manholeType'
    }).then(res => {
      this.typeList = res.manholeType || []
    })
  }

  // 获取项目信息及项目电子围栏
  getProjectLocation () {
    this.$axios.get(this.$apis.project.selectProjectAreaById, {
      projectId: this.projectId
    }).then((res) => {
      this.$nextTick(() => {
        this.loadMap(res.projectInfo, res.projectLocation)
      })
    })
  }

  // 渲染地图
  loadMap (projectInfo: {longitude: number; latitude: number}, list: Array<{ longitude: number; latitude: number; fenceCode: string }>) {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: []
    }).then(() => {
      const center: [number, number] = [Number(projectInfo.longitude), Number(projectInfo.latitude)]
      const map = new AMap.Map('map', {
        center: center,
        zoom: 11
      })
      this.map = map
      this.ploygons = drawPolygon(this.map, list || [], null, this.drawerClose)

      // 获取设备信息
      this.getData(true)

      // 点击地图关闭抽屉
      this.map.on('click', this.drawerClose)
    })
  }

  // 获取图标
  getIcon (deviceStatus: string, runState: string, isCurrent: boolean) {
    if (!isCurrent) {
      return (deviceStatus === '1' ? (runState === '1' ? this.warningImg : this.onlineImg) : this.offImg)
    } else {
      return (deviceStatus === '1' ? (runState === '1' ? this.warningImgCursor : this.onlineImgCursor) : this.offImgCursor)
    }
  }

  // 地图打点
  drawMarker (list: Array<TableData>) {
    if (this.markers.length && this.map) {
      this.map.remove(this.markers)
    }
    this.markers = []
    if (list && list.length > 0) {
      // 循坏将设备marker添加到地图
      list.forEach((item: TableData) => {
        if (item.longitude && item.latitude) {
          const icon = this.getIcon(item.deviceStatus, item.runState, false)
          const marker: any = new AMap.Marker({
            icon: new AMap.Icon({
              // 图标的取图地址
              image: icon
            }),
            offset: new AMap.Pixel(-18, -48),
            position: new AMap.LngLat(Number(item.longitude), Number(item.latitude))
          })
          marker.detail = item
          marker.on('click', (e: any) => {
            this.drawerOpen(e.target)
          })
          this.map && this.map.add(marker)
          this.markers.push(marker)
        }
      })
    }
  }

  // 抽屉打开
  drawerOpen (e: any) {
    if (this.drawerData === null || (this.drawerData && this.drawerData.deviceId !== e.detail.deviceId)) {
      if (this.drawerData && this.drawerData.deviceId !== e.detail.deviceId) {
        this.drawerClose()
      }
      this.drawerData = e.detail
      // 点击切换大图标
      const iconClick = this.getIcon(e.detail.deviceStatus, e.detail.runState, true)
      e.setOffset(new AMap.Pixel(-22, -64))
      e.setIcon(iconClick)
    }
    this.drawer = true
  }

  // 抽屉关闭
  drawerClose () {
    if (this.drawer) {
      this.drawer = false
      // 将图标替换
      if (this.map && this.drawerData) {
        const deviceId = this.drawerData.deviceId || ''
        const index = this.map.getAllOverlays('marker').findIndex((item: any) => item.detail.deviceId === deviceId)
        const icon = this.getIcon(this.drawerData.deviceStatus, this.drawerData.runState, false)
        const marker = this.map.getAllOverlays('marker')[index] as AMap.Marker
        marker.setOffset(new AMap.Pixel(-18, -48))
        marker.setIcon(icon)
      }

      // 清空抽屉
      this.drawerData = null
    }
  }

  // 实时状态点击
  leftDrawerClick () {
    if (this.leftDrawer) {
      this.leftDrawer = false
    } else {
      this.leftDrawer = true
      this.getData()
    }
  }

  // 列表被点击
  tableClick (row: TableData) {
    if (this.map) {
      const e = this.map.getAllOverlays('marker').find((item: any) => {
        return item.detail.manholeId === row.manholeId
      })
      this.drawerOpen(e)
    }
  }

  searchData () {
    this.page = 1
    this.getData()
  }
}
